import React, { useRef, useState, useEffect } from "react";
import { NavLink, Outlet, Link, useNavigate } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";
import "../Styles/baselayout.css";
import ray from "../Assets/logos/ray.svg";
import Footer from "./Footer";
import mainman from "../Assets/images/mainman.svg";
import { useTheme } from "../Context/Theme";
import { IoSunny, IoMoon } from "react-icons/io5";
import { IconContext } from "react-icons";
import Navbar from "./Navbar";

const Baselayout = () => {
  const { dark, toogleMode, setDark } = useTheme();
  const navigate = useNavigate();
  const navRef = useRef();

  const [showNav, setShowNav] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }

      setLastScrollY(window.scrollY);

      // Check if user has scrolled more than a specific amount to add the white line
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const navigation = [
    {
      name: "Work",
      path: "/Projects",
    },
    {
      name: "Info",
      path: "/About",
    },
    {
      name: "LinkedIn",
      icon: <GoArrowUpRight />,
      path: "https://www.linkedin.com/in/raymondmbam/",
      external: true,
    },
    {
      name: "Resume",
      icon: <GoArrowUpRight />,
      path: "https://drive.google.com/file/d/12NvsMHBeB5cDCXO2pQAln32usKtbBca6/view?usp=sharing",
      external: true,
    },
  ];

  const handleNav = () => {
    setShowNav(!showNav);
  };

  const handleWidth = () => {
    setWidth(window.innerWidth);
    if (width > 768) {
      setShowNav(false);
    }
  };

  window.addEventListener("resize", handleWidth);

  return (
    <div className="Base">
      {/*<div className="see" onClick={() => navigate('/building')}>
                See what I'm Building
            </div>*/}
      {/*<nav className={dark ? 'navigation darknavigation' : "navigation"}>*/}
      <Navbar />
      <main>
        <Outlet />
      </main>
    </div>
  );
};
export default Baselayout;

// import React, { useRef, useState, useEffect } from 'react'
// import { NavLink, Outlet, Link, useNavigate} from 'react-router-dom'
// import { GoArrowUpRight } from "react-icons/go";
// import '../Styles/baselayout.css'
// import ray from "../Assets/logos/ray.svg"
// import Footer from './Footer';
// import mainman from "../Assets/images/mainman.svg"
// import { useTheme } from '../Context/Theme';
// import { IoSunny, IoMoon } from "react-icons/io5";
// import { IconContext } from 'react-icons'

// const Baselayout = () => {
//     const { dark, toogleMode, setDark } = useTheme();
//     const navigate = useNavigate();
//     const navRef = useRef();

//     const [showNav, setShowNav] = useState(false);
//     const [width, setWidth] = useState(window.innerWidth);

//     const [showNavbar, setShowNavbar] = useState(true);
//     const [lastScrollY, setLastScrollY] = useState(window.scrollY);
//     const [isScrolled, setIsScrolled] = useState(false);

//     useEffect(() => {
//         const handleScroll = () => {
//           if (window.scrollY > lastScrollY) {
//             setShowNavbar(false);
//           } else {
//             setShowNavbar(true);
//           }

//           setLastScrollY(window.scrollY);

//           // Check if user has scrolled more than a specific amount to add the white line
//           if (window.scrollY > 50) {
//             setIsScrolled(true);
//           } else {
//             setIsScrolled(false);
//           }
//         };

//         window.addEventListener('scroll', handleScroll);

//     // Cleanup function to remove the event listener
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, [lastScrollY]);

//     const navigation = [
//         {
//             name: "Work",
//             path: "/Projects"
//         },
//         {
//             name: "Info",
//             path: "/About"
//         },
//         {
//             name: "LinkedIn",
//             icon: <GoArrowUpRight />,
//             path: "https://www.linkedin.com/in/raymondmbam/",
//             external: true
//         },
//         {
//             name: "Resume",
//             icon: <GoArrowUpRight />,
//             path: "https://drive.google.com/file/d/12NvsMHBeB5cDCXO2pQAln32usKtbBca6/view?usp=sharing",
//             external: true
//         }
//     ];

//     const handleNav = () => {
//         setShowNav(!showNav);
//     };

//     const handleWidth = () => {
//         setWidth(window.innerWidth);
//         if (width > 768) {
//             setShowNav(false);
//         }
//     };

//     window.addEventListener("resize", handleWidth);

//     return (
//         <div className='Base'>
//             {/*<div className="see" onClick={() => navigate('/building')}>
//                 See what I'm Building
//             </div>*/}
//             {/*<nav className={dark ? 'navigation darknavigation' : "navigation"}>*/}
//             <nav className={`navigation
//                 ${showNavbar ? 'active' : 'hidden'}
//                 ${isScrolled ? 'scrolled' : ''}
//                 ${dark ? "darkbg" : ""}
//                 `}>
//                 <div className='med-nav'>
//                     <div className='me-logo' onClick={() => navigate('/')}>
//                         <img src={mainman} alt="him" />
//                     </div>
//                     <div className={`mnbtn ${dark && 'darkText'}`} onClick={handleNav}>
//                         <p>Menu</p>
//                     </div>
//                 </div>
//                 <ul className={`${showNav ? "showNav" : 'setCenter'} ${dark && "darkul"}`} ref={navRef}>
//                     {navigation.map((item, index) => {
//                         return (
//                             <li key={index} className={dark ? " darkli" : "navItem"} onClick={() => setShowNav(false)}>
//                                 {item.external ? (
//                                     <a href={item.path} target="_blank" rel="noopener noreferrer">
//                                         <p>{item.name}</p>
//                                         <div>
//                                             {dark ?
//                                                 <IconContext.Provider value={{color:"white"}}>
//                                                     <GoArrowUpRight className="rotate-icon"/>
//                                                 </IconContext.Provider>
//                                                 :

//                                                 <IconContext.Provider value={{color:"black"}}>
//                                                     <GoArrowUpRight className="rotate-icon" />
//                                                 </IconContext.Provider>

//                                             }
//                                         </div>
//                                     </a>
//                                 ) : (
//                                     <NavLink to={item.path}>
//                                         <p>{item.name}</p>
//                                         {item.icon}
//                                     </NavLink>
//                                 )}
//                             </li>
//                         );
//                     })}
//                 </ul>
//                 <div className='logo' onClick={toogleMode}>
//                     {/**<img src={ray} alt="logo" />*/}
//                     <div  className='modes'>
//                     <IconContext.Provider value={{size:"20px"}}>
//                         {dark ?
//                             <IoSunny/>
//                             :
//                             <IoMoon />

//                         }
//                     </IconContext.Provider>
//                     </div>

//                 </div>
//             </nav>
//             <main>
//                 <Outlet />
//             </main>
//         </div>
//     );
// }
// export default Baselayout

// const Baselayout = () => {

//     const {dark, toogleMode, setDark} = useTheme()

//     // const location = useLocation()
//     const navigate = useNavigate()
//     const navRef = useRef()

//     const [showNav, setShowNav] = useState(false)
//     const [width, setWidth] = useState(window.innerWidth)

//     const navigation = [
//         {
//             name:"Projects",
//             path:"/Projects"
//         },

//         {
//             name:"Info",
//             path:"/About"
//         },

//         {
//             name:"LinkedIn",
//             icon:<GoArrowUpRight />,
//             path:"https://www.linkedin.com/in/raymondmbam/"
//         },

//         {
//             name:"Resume",
//             icon:<GoArrowUpRight />,
//             path:"https://docs.google.com/document/d/1DdVvdoCAKCc_UTekmYkRql2DhaCLlHQnhiuq7Nu19oU/edit?usp=sharing"
//         },

//     ]
//     //function to display navigation
//     const handleNav = () =>{
//         setShowNav(!showNav)
//     }

//     const handleWidth = () =>{
//         setWidth(window.innerWidth)
//         if(width > 768){
//             setShowNav(false)
//         }
//     }

//     window.addEventListener("resize", handleWidth)

//   return (
//     <div className='Base'>

//         <div onClick={() => navigate('/building')} className="see">
//             See what I'm Building
//         </div>
//             <nav className={dark ? 'navigation darknavigation': "navigation"}>

//             <div className='med-nav'>
//                 <div className='me-logo' onClick={()=>navigate('/')}>
//                     <img src={mainman} alt="him"  />
//                 </div>
//                 <div className={`mnbtn ${dark && 'darkText'}`} onClick={handleNav}>
//                    <p>Menu</p>
//                 </div>
//             </div>

//             {/**Logo  */}

//                 <ul className={` ${showNav ? "showNav": 'setCenter' } ${dark && "darkul" }`} ref={navRef}>
//                     {navigation.map((item, index) =>{
//                     return (
//                         <li key={index} className={dark ? " darkli": "navItem" } onClick={() => setShowNav(false)}>
//                             <NavLink to = {item.path}>
//                             <p> {item.name}</p>
//                             {item.icon}
//                             </NavLink>

//                         </li>
//                     )
//                     })}
//                 </ul>

//                 <div className='logo' onClick={toogleMode} >
//                     <img src={ray} alt="logo" />
//                 </div>
//             </nav>
//             <main>
//                 <Outlet />
//                 <Footer />
//             </main>

//     </div>
//   )
// }
