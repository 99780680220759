import { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import BlockContent from "@sanity/block-content-to-react"
import { client } from "../client"
import { Helmet } from 'react-helmet';
import { useTheme } from '../Context/Theme'
import '../Styles/ThoughtStyles/ThoughtStyles.css'
import { format } from "date-fns"
import Button from '../Resources/Button'


export default function OneThought() {
  const [singlePost, setSinglePost] = useState([])
  const { slug } = useParams()

  useEffect(() => {
    client.fetch(`*[slug.current == "${slug}"] {
      title,
      slug,
      spotify,
      apple,
      youtube,
      body,
      publishedAt,
      mainImage {
        asset -> {
          _id,
          url
        },
        alt,
      },
      "name": author->name,
  }`).then((data) => setSinglePost(data[0])).catch(console.error)
  }, [slug])


  return <>
  <div className='thts-pg'>
    <Helmet>
     <title>Thoughts | Raymond Mbam</title>
     <meta name="description" content="Raymond Mbam - Product Designer & Strategist" />
   </Helmet>

   <div className='thts-blog'>
   

  <p className="thts-Head">{singlePost.title}</p>
  <p className="thts-Head">{singlePost.spotify}</p>

  {/* Published At Date, formatted using `date-fns` in the format Day/Month/Year */}
  {singlePost.publishedAt && (
    <p className="thts-date"lassName="thts-date">{format(new Date(singlePost.publishedAt), "MMMM yyyy")}</p>
  )}

  <div>
  {/* Check for whether a preview image exists and display it if it does */}
  {singlePost.mainImage && singlePost.mainImage.asset && (
   <img className="thts-coverimg" src={singlePost.mainImage.asset.url} alt={singlePost.title} />
  )}
  
  {/* Display your blog content - text, images, heading, links */}
  <div className="thts-content">
  <BlockContent
    blocks={singlePost.body}
    projectId="8tyap0eh"
    dataset="production"
  />
</div>
  {/* Link back to your main blog post page */}
  <Button name="Read More Of My Thoughts" action="/thoughts"  className="global-button white" />
  </div>
</div>
</div>
</>
}
