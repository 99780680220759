import { createBrowserRouter, 
  createRoutesFromElements, 
  Route, 
  RouterProvider } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from './Context/Theme';
import Baselayout from './Resources/Baselayout';
import Home from './Pages/Home';
import Project from './Pages/Project';
import About from './Pages/About';
import SeeBuild from './Pages/SeeBuild';
import Alps from './Pages/CaseStudies/AlpsHelpCenter';
import Maildrip from './Pages/CaseStudies/Maildrip';
import Pickuppal from './Pages/CaseStudies/Pickuppal';
import Buff from './Pages/CaseStudies/Buff';
import CoverLetter from './Pages/CaseStudies/CoverLetter';
import Grapes from './Pages/CaseStudies/Grapes';
import AllThoughts from './Pages/AllThoughts';
import Blog from './Pages/Blog/Blog';
import Article from './Pages/Blog/Article';
import OneThought from './Pages/OneThought';
import TestCS from './Pages/CaseStudies/TestCS';
import Navbar from './Resources/Navbar';
import PodcastHome from './Pages/PodcastHome';
import OneEpisode from './Pages/OneEpisode';
import AllEpisodes from './Pages/AllEpisodes';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element = {<Baselayout />}>
      <Route path="/" element = {<Home />} />
      <Route path="/Projects" element ={<Project />} />
      <Route path="/About" element={<About />} />
      <Route path="/building" element ={<SeeBuild />} />
      <Route path ="/alps" element ={<Alps />} />
      <Route path  = "/maildrip" element = {<Maildrip />} />
      <Route path  = "/grapes" element = {<Grapes />} />
      <Route path='/pickuppal' element = {<Pickuppal />} />
      <Route path ="/buff" element ={<Buff />} />
      <Route path ="/cover-letter" element ={<CoverLetter />} />
      <Route path ="/ts" element ={<TestCS />} />
      <Route path ="/thoughts" element ={<AllThoughts />} />
      <Route path ="/thoughts/:slug" element ={<OneThought />} />
      <Route path ="/podcast/ep/:slug" element ={<OneEpisode />} />
      <Route path ="/podcast/ep" element ={<AllEpisodes />} />
      <Route path ="/podcast" element ={<PodcastHome />} />
      <Route path ="/blog" element ={<Blog />} />
      <Route path ="/blog/:title" element ={<Article />} />
    </Route>
  )
)

function App() {
  return (
    <div className='app'>
         {/**  */}
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </div>
   
  );
}

export default App;
