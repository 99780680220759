import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Navbar.css";
import mainman from "../Assets/images/mainman.svg";

const Navbar = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    // Handle resize to track window width
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }

      setLastScrollY(window.scrollY);

      // Check if user has scrolled more than a specific amount to add the white line
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [lastScrollY]);

  const navigate = useNavigate();

  // Toggle menu visibility for mobile
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div
      className={`navbar
      ${showNavbar ? "active" : "hidden"}
      ${isScrolled ? "scrolled" : ""}
    `}
    >
      <div className="navbar-left">
        <div className="me-logo" onClick={() => navigate("/")}>
          <img src={mainman} alt="Logo" />
        </div>
        {/* Show Home and About only on Desktop */}
        {width >= 768 && (
          <>
            <Link className="home-link" to="/">
              Home
            </Link>
            <Link className="home-link" to="/about">
              About Me
            </Link>
          </>
        )}
      </div>

      {/* Hamburger menu for mobile */}
      {width < 768 && (
        <div className="hamburger" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      )}

      {/* Right navigation for desktop or dropdown for mobile */}
      <div
        className={`navbar-right ${
          width < 768 && isMenuOpen ? "dropdown show" : ""
        }`}
      >
        {/* Home and About Me for mobile dropdown */}
        {width < 768 && (
          <>
          <div className="dropdown-header">
        <div className="me-logo" onClick={() => navigate("/")}>
          <img src={mainman} alt="Logo" />
          {/* Cancel button to close the menu */}
        </div>
        <button className="cancel-btn" onClick={closeMenu}>✖</button>
      </div>
            <Link className="home-link" to="/">
              Home
            </Link>
            <Link className="home-link" to="/about">
              About Me
            </Link>
          </>
        )}
        <Link to="https://www.linkedin.com/in/raymondmbam/">LinkedIn</Link>
        <Link to="https://drive.google.com/file/d/12NvsMHBeB5cDCXO2pQAln32usKtbBca6/view?usp=sharing">
          Resume
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
