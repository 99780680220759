import React, { useState, useEffect } from "react";
import "../Resources/Hero.css";
import { useTheme } from "../Context/Theme";
import { PiLightningBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { client } from "../client";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import micSvg from "../Assets/images/micSvg.svg";
import "./Podcast.css";

function AllEpisodes() {
  const { dark } = useTheme();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"] {
      title,
      slug,
      guest,
      body,
      episode,
      categories[
        {
          _key,
          _ref,
          title,
        }],
      publishedAt,
      mainImage {
        asset -> {
          _id,
          url
        },
        alt,
      }
    } | order(publishedAt desc)`
      )
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);

  return (
    <section className={`hero-section ${dark && "darkbg"}`}>
      <Helmet>
        <title>Lead Design Build | Raymond Mbam</title>
        <meta
          name="description"
          content="Raymond Mbam - Product Designer & Strategist"
        />
      </Helmet>
      <div className="hero-content">
        <h1 className={`hero-title ${dark && "darkText"}`}>All Episodes</h1>

        <div className="grid-container">
        {posts &&
          posts.map((post, index) => (
            <Link className="card"
            to={"/podcast/ep/" + post.slug.current}
                  key={post.slug.current}>
              {post.mainImage && post.mainImage.asset && (
                <div className="ci-container">
                  <img
                    className="card-image"
                    src={post.mainImage.asset.url}
                    alt={post.title}
                  />
                </div>
              )}
              <div className="card-content">
                <div className="host">
                  <div className="host-text">Guest:</div>

                  <div className="host-name">{post.guest}</div>
                </div>
                <p className="card-title">{post.title}</p>
                <div className="host">
                <div className="host-text">EP:</div>

                <div className="host-name">{post.episode}</div>
              </div>
              {/*<button className="button-link">
                  <Link
                  className="button-link"
                  to={"/podcast/ep/" + post.slug.current}
                  key={post.slug.current}
                >
                  Listen Now
                </Link>
              </button> */}
              </div>
              
            </Link>
          ))}
      </div>
      </div>
    </section>
  );
}

export default AllEpisodes;
