import React, { useState, useEffect } from "react";
import "../Resources/Hero.css";
import { useTheme } from "../Context/Theme";
import { PiLightningBold } from "react-icons/pi";
import { useParams, Link } from "react-router-dom";
import { client } from "../client";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import Grad from "../Assets/images/maildrip-cover.png";
import "./Podcast.css";
import BlockContent from "@sanity/block-content-to-react";
import Button from "../Resources/Button";
import Spotify from "../Assets/images/spotify.png";
import Apple from "../Assets/images/apple-podcast.png";
import Youtube from "../Assets/images/yt-music.png";

function OneEpisode() {
  const { dark } = useTheme();
  const [singlePost, setSinglePost] = useState([]);
  const { slug } = useParams();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"] {
      title,
      slug,
      guest,
      body,
      episode,
      categories[
        {
          _key,
          _ref,
          title,
        }],
      publishedAt,
      mainImage {
        asset -> {
          _id,
          url
        },
        alt,
      }
    } | order(publishedAt desc)`
      )
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"] {
      title,
      slug,
      episode,
      spotify,
      apple,
      youtube,
      guest,
      body,
      publishedAt,
      largeImage {
        asset -> {
          _id,
          url
        },
        alt,
      },
      mainImage {
        asset -> {
          _id,
          url
        },
        alt,
      },
      "name": author->name,
  }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);

  // Function to handle button click and open a URL in a new page
  const handleButtonClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <section className={`hero-section ${dark && "darkbg"}`}>
      <Helmet>
        <title>Lead Design Build | Raymond Mbam</title>
        <meta
          name="description"
          content="Raymond Mbam - Product Designer & Strategist"
        />
      </Helmet>
      <div className="hero-content">
        <div className="podcast-page">
      

          {/* Podcast Title */}
          <h1 className="podcast-title">{singlePost.title}</h1>
          <h4>Listen to this episode on:</h4>
          <div className="button-container">
            <button
              className="play-button"
              onClick={() => handleButtonClick(singlePost.spotify)}
            >
              <img src={Spotify} alt="Spotify" className="play-logo" />
              Spotify
            </button>

            <button
              className="play-button"
              onClick={() => handleButtonClick(singlePost.apple)}
            >
              <img src={Apple} alt="Spotify" className="play-logo" />
              Apple Podcast
            </button>

            <button
              className="play-button"
              onClick={() => handleButtonClick(singlePost.youtube)}
            >
              <img src={Youtube} alt="Spotify" className="play-logo" />
              Youtube
            </button>
          </div>
          <BlockContent
            blocks={singlePost.body}
            projectId="8tyap0eh"
            dataset="production"
          />

          {/*  Podcast Image with Gradient Overlay */}
          {/* <div className="podcast-image-container">
            {singlePost.mainImage && singlePost.mainImage.asset && (
              <img
                className="podcast-image"
                src={singlePost.mainImage.asset.url}
                alt={singlePost.title}
              />
            )}
          </div>
          */}
        </div>
        <div>
          <p className="h2-title"> Related</p>
          <h2 className="h2-title">Latest Episodes</h2>

          <div className="grid-container">
            {posts &&
              posts.map((post, index) => (
                <Link
                  className="card"
                  to={"/podcast/ep/" + post.slug.current}
                  key={post.slug.current}
                >
                  {post.mainImage && post.mainImage.asset && (
                    <div className="ci-container">
                      <img
                        className="card-image"
                        src={post.mainImage.asset.url}
                        alt={post.title}
                      />
                    </div>
                  )}
                  <div className="card-content">
                    <div className="host">
                      <div className="host-text">Guest:</div>

                      <div className="host-name">{post.guest}</div>
                    </div>
                    <p className="card-title">{post.title}</p>
                    <div className="host">
                      <div className="host-text">EP:</div>

                      <div className="host-name">{post.episode}</div>
                    </div>
                    {/*<button className="button-link">
                  <Link
                  className="button-link"
                  to={"/podcast/ep/" + post.slug.current}
                  key={post.slug.current}
                >
                  Listen Now
                </Link>
              </button> */}
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default OneEpisode;
