import React, { useState, useEffect } from "react";
import "../Resources/Hero.css";
import { useTheme } from "../Context/Theme";
import { PiLightningBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { client } from "../client";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import LDB from "../Assets/images/ldbLogo.png";
import "./Podcast.css";
import micSvg from "../Assets/images/micSvg.svg";
import Spotify from "../Assets/images/spotify.png";
import Apple from "../Assets/images/apple-podcast.png";
import Youtube from "../Assets/images/yt-music.png";

function HeroSection() {
  const { dark } = useTheme();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"] {
      title,
      slug,
      guest,
      body,
      episode,
      categories[
        {
          _key,
          _ref,
          title,
        }],
      publishedAt,
      mainImage {
        asset -> {
          _id,
          url
        },
        alt,
      }
    } | order(publishedAt desc)`
      )
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);

  return (
    <section className={`hero-section ${dark && "darkbg"}`}>
      <Helmet>
        <title>Lead Design Build | Raymond Mbam</title>
        <meta
          name="description"
          content="Raymond Mbam - Product Designer & Strategist"
        />
      </Helmet>

      <div className="pod-grid">
        <img className="image-contnr" alt="Lead Design Build Image" src={LDB} />

        <div className="div-2">
          <h1 className="h1-title">Lead Design Build</h1>
          <p className="p-subtext">
            Exploring the intersection of design, technology, and business
            through conversations with top product leaders, visionary
            entrepreneurs, design heads, and software experts across the
            industry.{" "}
          </p>

          <button className="primary-button">
            <Link to="/podcast/ep" className="button-link">
              See all episodes
            </Link>
          </button>
          <div className="button-container">
            <Link
              className="play-button"
              to="https://open.spotify.com/show/35HAHSBXYAbAf4SpBKe8fG?si=cc22269625fa4769"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Spotify} alt="Spotify" className="play-logo" />
              Spotify
            </Link>

            <Link
              className="play-button"
              to="https://podcasts.apple.com/us/podcast/lead-design-build/id1772106250"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Apple} alt="Spotify" className="play-logo" />
              Apple Podcast
            </Link>

            <Link
              className="play-button"
              to="https://music.youtube.com/playlist?list=PLX902iJEKS9S1_vkuIyDBu5B_4i74AeV-"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Youtube} alt="Spotify" className="play-logo" />
              Youtube
            </Link>
          </div>
        </div>
      </div>

      <p className="h2-title"> New Arrivals</p>
      <h2 className="h2-title">Latest Episodes</h2>

      <div className="grid-container">
        {posts &&
          posts.map((post, index) => (
            <Link
              className="card"
              to={"/podcast/ep/" + post.slug.current}
              key={post.slug.current}
            >
              {post.mainImage && post.mainImage.asset && (
                <div className="ci-container">
                  <img
                    className="card-image"
                    src={post.mainImage.asset.url}
                    alt={post.title}
                  />
                </div>
              )}
              <div className="card-content">
                <div className="host">
                  <div className="host-text">Guest:</div>

                  <div className="host-name">{post.guest}</div>
                </div>
                <p className="card-title">{post.title}</p>
                <div className="host">
                  <div className="host-text">EP:</div>

                  <div className="host-name">{post.episode}</div>
                </div>
                {/*<button className="button-link">
                  <Link
                  className="button-link"
                  to={"/podcast/ep/" + post.slug.current}
                  key={post.slug.current}
                >
                  Listen Now
                </Link>
              </button> */}
              </div>
            </Link>
          ))}
      </div>
    </section>
  );
}

export default HeroSection;
